import React,{useEffect} from "react";
import {
  CandidatoSection,
  CandidatoText,
  Title,
  CandidatosContainer,
  CandidatoPodemos,
  Imagem,
  Nome,
  TextDesc,
  Overlay,
  ConhecerButton,
} from "./Candidatos";
import 'aos/dist/aos.css';
import AOS from 'aos';
import eduardomagoga from "../../img/candidatos/eduardomagoga.jpg"
import abraao from "../../img/candidatos/abraao.jpg"
import alexandrerangel from "../../img/candidatos/alexandrerangel.jpg"
import andresouza from "../../img/candidatos/andresouza.jpg"
import camposfilho from "../../img/candidatos/camposfilho.jpg"
import cinthiaprestes from "../../img/candidatos/cinthiaprestes.jpg"
import cirilo from "../../img/candidatos/cirilo.png"
import danilopalmaf from "../../img/candidatos/danilopalmaf.png"
import instrutorgomes from "../../img/candidatos/instrutorgomes.jpg"
import elyprotetora from  "../../img/candidatos/elyprotetora.jpg"
import fatimasantos from "../../img/candidatos/fatimasantos.jpg"
import flaviapereira from "../../img/candidatos/flaviapereira.jpg"
import geradaniel from "../../img/candidatos/geradaniel.png"
import gianportuga from "../../img/candidatos/portugagian.png"
import hebertganem from "../../img/candidatos/hebertganem.png"
import joaomoura from "../../img/candidatos/JoaoMoura.png"
import lucinhalima from "../../img/candidatos/lucinhalima.jpg"
import luizinhogonzaga from "../../img/candidatos/luizinhogonzaga.jpg"
import lucianamendes from "../../img/candidatos/lucianamendes.jpg"
import marcioaugusto from "../../img/candidatos/marcioaugusto.png"
import mariahray from "../../img/candidatos/mariahneves.jpg"
import marcosceara from "../../img/candidatos/marcosceara.jpg"
import mineirodoespetinho from "../../img/candidatos/mineirodoespetinho.jpg"
import miroaparecido from "../../img/candidatos/miroaparecido.jpg"
import profavaleria from "../../img/candidatos/profavaleriatorres.png"
import thata from "../../img/candidatos/Thata.png"
import renansalomao from "../../img/candidatos/renansalomao.jpg"
import rogergermano from "../../img/candidatos/rogergermano.jpg"
import rosilima from "../../img/candidatos/rosilima.jpg"
import sergioray from "../../img/candidatos/sergioray.jpg"
import soniajusti from "../../img/candidatos/sonia justi.png"
import tenente from "../../img/candidatos/tenente.jpg"
import galtieri from "../../img/candidatos/galtieri.png"
import zangao from "../../img/candidatos/zangao.jpg"

function Candidatos() {
  useEffect(() => {
    AOS.init({ duration: 1800 });
  }, []);
  return (
    <CandidatoSection>
      <CandidatoText>
        <Title>Candidatos</Title>
      </CandidatoText>
      <CandidatosContainer>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={abraao} />
          <Overlay>
            <a  href="https://www.instagram.com/abraaostreyoficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Abrãao Strey</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={alexandrerangel} />
          <Overlay>
            <a  href="https://www.instagram.com/alexandrerangel76/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Alexandre Rangel</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={andresouza} />
          <Overlay>
            <a  href="https://www.instagram.com/andresouzadobem/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>André Souza</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={camposfilho} />
          <Overlay>
            <a  href="https://www.instagram.com/camposfilho.oficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Campos Filho</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={cinthiaprestes} />
          <Overlay>
            <a  href="https://www.instagram.com/cinthiaprestesconsultora/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Cinthia prestes</Nome>
        </CandidatoPodemos>

        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={instrutorgomes} />
          <Overlay>
            <a  href="https://www.instagram.com/instrutor.gomes/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Instrutor Gomes</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={eduardomagoga} />
          <Overlay>
            <a  href="https://www.instagram.com/eduardomagogaoficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Eduardo Magoga</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={elyprotetora} />
          <Overlay>
            <a  href="https://www.instagram.com/henriqueeliangela/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Eliângela Protetora</Nome>
        </CandidatoPodemos>

        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={fatimasantos} />
          <Overlay>
            <a  href="https://www.instagram.com/fatima100167/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Fátima do bar</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={flaviapereira} />
          <Overlay>
            <a  href="https://www.instagram.com/_flaviapereiraoficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Cantora Flávia Pereira</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={gianportuga} />
          <Overlay>
            <a  href="https://www.instagram.com/giantorresoficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Gian, Baggio e Portuga Unidos</Nome>
        </CandidatoPodemos>

        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={geradaniel} />
          <Overlay>
            <a  href="https://www.instagram.com/geraldo_ribeiro_geraldo/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Gera Ribeiro e Daniel Unidos</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={hebertganem} />
          <Overlay>
            <a  href="https://www.instagram.com/hebertganem/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Hebert Ganem</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={joaomoura} />
          <Overlay>
            <a  href="https://www.instagram.com/jbmoura98/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>João Moura</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={lucianamendes} />
          <Overlay>
            <a  href="https://www.instagram.com/luciana.mendes_oficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Luciana Mendes</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={lucinhalima} />
          <Overlay>
            <a  href="https://www.instagram.com/lucinhaswisslima/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Lucinha Lima</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={luizinhogonzaga} />
          <Overlay>
            <a  href="https://www.instagram.com/luizinhogonzagacampinas/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Luizinho Gonzaga</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={cirilo} />
          <Overlay>
            <a  href="https://www.instagram.com/luizhcirilo/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Luiz Cirilo</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={danilopalmaf} />
          <Overlay>
            <a  href="https://www.instagram.com/danilopalmasp/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Danilo Palma</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={marcioaugusto} />
          <Overlay>
            <a  href="https://www.instagram.com/marcioaugusto20200/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Marcio Augusto</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={mariahray} />
          <Overlay>
            <a  href="https://www.instagram.com/mariahnevesoficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Mariah Neves</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={marcosceara} />
          <Overlay>
            <a  href="https://www.instagram.com/marcoscearaoficial/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Marcos Ceará</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={mineirodoespetinho} />
          <Overlay>
            <a  href="https://www.instagram.com/mineiro_do_espetinho/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Mineiro do Espetinho</Nome>
        </CandidatoPodemos>

        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={miroaparecido} />
          <Overlay>
            <a  href="https://www.instagram.com/miroaparecido/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Miro Aparecido</Nome>
        </CandidatoPodemos>

        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={profavaleria} />
          <Overlay>
            <a  href="https://www.instagram.com/profvaleriatorres/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Professora Valeria Torres</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={galtieri} />
          <Overlay>
            <a  href="https://www.instagram.com/galteriocampinas/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Paulo Galtério</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={renansalomao} />
          <Overlay>
            <a  href="https://www.instagram.com/renansalomaocampinas/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Renan Salomão</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={rogergermano} />
          <Overlay>
            <a  href="https://www.instagram.com/rogergermano/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Roger Germano</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={rosilima} />
          <Overlay>
            <a  href="https://www.instagram.com/rosilimaii/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Rosi Lima</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={sergioray} />
          <Overlay>
            <a  href="https://www.instagram.com/sergio_santiago_silva/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Sérgio Santiago e apoio Ray do Varejão</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={soniajusti} />
          <Overlay>
            <a  href="https://www.instagram.com/sonia_justi/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Sonia Justi</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={tenente} />
          <Overlay>
            <a  href="https://www.instagram.com/furioso.jose.carlos/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Tenente Furioso</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={thata} />
          <Overlay>
            <a  href="https://www.instagram.com/thaisolhosaventureiros/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Thatá</Nome>
        </CandidatoPodemos>
        <CandidatoPodemos data-aos="fade-up">
          <Imagem src={zangao} />
          <Overlay>
            <a  href="https://www.instagram.com/zangaodooziel/" target="_blank"><ConhecerButton>Conhecer</ConhecerButton></a>
          </Overlay>
          <Nome>Zangão do Oziel</Nome>
        </CandidatoPodemos>
      </CandidatosContainer>
      <TextDesc> Os melhores candidatos, para a melhor cidade!</TextDesc>
    </CandidatoSection>
  );
}

export default Candidatos;
