import { primaryColor, secundaryColor } from '../../config/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  color: white;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.8rem;
  position: fixed; 
  background: ${({ scrolled }) => (scrolled ? `${primaryColor}` : 'transparent')};
  transition: background 0.3s ease-in-out;
  @media (max-width: 768px) {
    padding: 0 10px; 
  }
  
`;

export const Logo = styled.img`
  width: 150px;
  margin-top: 12px;
  margin-left: 99px;
  @media (max-width: 768px) {
   padding: 0;
   margin-left: 0;
   width: 130px;
  }
`;

export const Nav = styled.nav`
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0 40px;

    li {
      margin: 0 10px;
      padding: 0 10px;

      a {
        color: ${({ scrolled }) => (scrolled ? 'black' : 'white')};
        text-decoration: none;
        font-size: 17.5px;
        transition: color 0.3s ease-in-out;
      }
    }
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: inherit;
    top: 90px;
    background: ${secundaryColor};
    width: 100%;
    height: 400px;
    padding-top: 20px;
    left: 0;
  
    ul {
      flex-direction: column;
      align-items: center;
      transition: all 0.3s ease-in-out;
      margin: 0;
      gap: 30px;
    }
    li {
      font-size: 1.9rem;
    }
  }
`;

export const HamburgerMenu = styled(FontAwesomeIcon)`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    width: 80px; 
    height: 40px;

  }
`;


