import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { ChatButtonContainer, ChatButton, ChatFrameContainer,  } from './BtnChat.js';

function BtnChat() {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <>
      <ChatButtonContainer>
        <ChatButton onClick={toggleChat}>
        <FontAwesomeIcon icon={faComment} />
        </ChatButton>
      </ChatButtonContainer>
      {isChatOpen && (
        <ChatFrameContainer>
          <iframe
            src="https://retune.so/share/chat/11ef3d47-bf4b-2500-9f7b-594190f80fea/widget"
            width="350"
            height="400"
            style={{ border: 0, background: 'white' }}
            title="Chat Frame"
          ></iframe>
        </ChatFrameContainer>
      )}
    </>
  );
}

export default BtnChat;