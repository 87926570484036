import { fourtyColor, secundaryColor, primaryColor, buttoncolor,buttonback } from '../../config/colors';
import styled,{ keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const HomeSection = styled.section`
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  @media (max-width: 768px) {
    padding: 0;
    margin-top: -20px;
    height: 400px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 7rem;
  overflow: hidden;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 3.3rem;
  font-weight: 900;
  color: ${fourtyColor};
  animation: ${fadeIn} 1s ease-in-out forwards;
  animation-delay: 0.3s;
  opacity: 0;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 1.7rem;
    font-weight: 900;
  }
`;



export const Text = styled.p`
  font-size: 1.2rem;
  margin: 2.4rem 0;
  color:${fourtyColor};
  animation: ${fadeIn} 1s ease-in-out forwards;
  animation-delay: 0.6s;
  opacity: 0;

  overflow: hidden;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Button = styled.button`
  font-size: 1.7rem;
  animation: ${fadeIn} 1s ease-in-out forwards;
  animation-delay: 0.9s;
  overflow: hidden;
  opacity: 0;
  width: 220px;
  height: 70px;
  background: transparent;
  text-transform: uppercase;

  color: ${fourtyColor};
  border: 3px solid ${fourtyColor};

  cursor: pointer;
  font-weight: 600;
  transition: 1s;

  a {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    background: ${buttonback};
    color: ${fourtyColor};
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
    width: 210px;
    height: 40px;
    padding: 0;
  }
`;

export const Image = styled.img`
  width: 450px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;
