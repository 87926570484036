import { fourtyColor, secundaryColor } from '../../config/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  color: white;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.8rem;
  background: ${secundaryColor};
  @media (max-width: 768px) {
    padding: 0 12px; 
    margin-top: -10px;
  }
  
`;

export const Logo = styled.img`
  width: 100px;
  margin-top: 12px;
  margin-left: 99px;
  @media (max-width: 768px) {
   padding: 0;
   margin-left: 0;
   width: 90px;
  }
`;

export const Text = styled.nav`

  font-size: 1.3rem;
  color: ${fourtyColor};
  font-weight: 500;
  margin-right :160px;

  @media (max-width: 768px) {
   font-size: 1rem;
   margin-right: 30px;
  }
`;



