
import './App.css';
import Candidatos from './pages/Candidatos/Candidatos.jsx';
import Contato from './pages/Contato/Contato.jsx';
import Footer from './pages/Footer/Footer.jsx';
import Header from './pages/Header/Header.jsx';
import Home from './pages/home/Home.jsx';
import Ideais from './pages/Ideais/Ideais.jsx';
import Partido from './pages/Partido/Partido.jsx';
import BtnChat from './components/BtnChat/BtnChat.jsx';


function App() {

  return (
    <div className="appWrapper">
    
      <div className="backgroundImage">
        <Header />
        <section id="home">
          <Home />
        </section>
      </div>
      <section id="partido">
        <Partido />
      </section>
      <section id="candidatos">
        <Candidatos />
      </section>
      <section id="ideais">
        <Ideais />
      </section>
      <section id="faleconosco">
        <Contato />
      </section>
      <Footer />
      <BtnChat />
    </div>
  );
}

export default App;
