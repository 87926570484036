import React from "react";
import {
  HomeSection,

  TextWrapper,
  Title,
  Text,
  Button,
} from "./Home";
// import sampleImage from '../../img/campos.png';

function Home() {
  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <HomeSection id="home">
    <TextWrapper>
      <Title>
        O partido que mais cresce, <br />
        Na cidade mais tecnologica
      </Title>
      <Text>
        Somos o do futuro e o mais tecnologico do pais
         <br />
        #JuntosPodemos+Campinas
      </Text>
      <Button>
        <a href='https://filie.podemos.org.br/'  target="_blank" >
          Filie-se
        </a>
      </Button>
    </TextWrapper>
  </HomeSection>
  );
}

export default Home;
