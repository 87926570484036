import { secundaryColor, fourtyColor, thirdColor, desctext, primaryColor } from '../../config/colors'
import fundo from "../../img/fundacaofundo.jpg";
import styled from 'styled-components';

export const ContatoSection = styled.section`
margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: ${secundaryColor};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageDiv = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: url(${fundo});
  background-size: cover;
  background-position: center;
  color: white;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
`;

export const Title = styled.h1`
  position: relative;
  z-index: 1;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const Text = styled.p`
position: relative;
  z-index: 1;
  font-size: 1rem;
  font-weight: 300;
  width: 80%;
  margin-bottom: 1rem;
  text-align: center;
`;

export const LogoFundacao = styled.img`
  position: relative;
  z-index: 1;
  width: 90px;
  height: auto;
  margin: 1rem 0;
`;

export const ButtonFundacao = styled.button`
  position: relative;
  z-index: 1;
  padding: 13px 55px;
  background-color: ${primaryColor};
  color: ${fourtyColor};
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
`;

export const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const AreaText = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const ButtonForm = styled.button`
  width: 100%;
  height: 40px;
  background-color: ${secundaryColor};
  color: ${fourtyColor};
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  cursor: pointer;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
`;

export const SvgIcon = styled.svg`
  width: 30px;
  height: 30px;
  fill: ${secundaryColor};
`;