import React from "react";
import { FooterWrapper, Logo, Text } from "./Footer";
import logopodemos from '../../img/podemoscampinas.png'

function Footer() {
  

  return (
    <FooterWrapper>
      <Logo src={logopodemos} alt="Logo" />
      <Text>Copyright©PODEMOSCAMPINAS</Text>
    </FooterWrapper>
  );
};

export default Footer;
