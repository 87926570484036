import { secundaryColor} from '../../config/colors'
import styled from 'styled-components';

export const ChatButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px; /* Posição do botão de chat */
  z-index: 1000;
`;

export const ChatButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${secundaryColor};
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 24px;

  i {
    pointer-events: none;
  }

  @media (max-width: 768px) {
   
   width: 45px;
   height: 45px;
   font-size: 22px;
}
`;

export const ChatFrameContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 350px;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Garante que fique acima de outros elementos */

  @media (max-width: 768px) {
   align-items: center;
   justify-content: center;
   right: 0;
   width: 300px;
   height: 400px;
}
`;