import React,{useEffect} from "react";
import {
  IdeaisSection,
  IdeaisText,
  Title,
  IdeaisContainer,
  IdeaisPodemos,
  IdeaisPodemosGreen,
  TitleIdeaisWhite,
  TextIdeaisWhite,
  TitleIdeais,
  TextIdeais,
  ButtonEstatuto
} from "./Ideais";
import 'aos/dist/aos.css';
import AOS from 'aos';


function Ideais() {
  useEffect(() => {
    AOS.init({ duration: 1800 });
  }, []);
  return (
    <IdeaisSection data-aos="fade-up">
      <IdeaisText>
        <Title>Ideais</Title>
      </IdeaisText>
      <IdeaisContainer>
        <IdeaisPodemosGreen>
          <TitleIdeaisWhite>Missão</TitleIdeaisWhite>
          <TextIdeaisWhite>
            Ser um instrumento popular para a transformação do país
          </TextIdeaisWhite>
        </IdeaisPodemosGreen>
        <IdeaisPodemos>
          <TitleIdeaisWhite>Visão</TitleIdeaisWhite>
          <TextIdeaisWhite>
            Consolidar-se como referência ética, de práticas democráticas e
            duplicar a representatividade eleitoral no Brasil
          </TextIdeaisWhite>
        </IdeaisPodemos>
        <IdeaisPodemosGreen>
          <TitleIdeaisWhite>Valores</TitleIdeaisWhite>
          <TextIdeaisWhite>
            • Transparência • Responsabilidade • Paixão • Participação •
            Humanismo
          </TextIdeaisWhite>
        </IdeaisPodemosGreen>
      </IdeaisContainer>
      <a href="https://filie.podemos.org.br/assets/documents/TSE-estatuto-do-Podemos-de-19.2.2016-aprovado-16.5.2017.pdf" target="_blank"><ButtonEstatuto>Estatuto Podemos</ButtonEstatuto></a>
    </IdeaisSection>
  );
}

export default Ideais;
