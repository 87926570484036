import { secundaryColor, fourtyColor, primaryColor, desctext } from '../../config/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const PartidoSection = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    padding: 0;
    margin: 20px 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; 
  padding: 0 80px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const TextWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 100px;
  text-align: left;
 

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding-left: 0;
    margin-top: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 900;
  color: ${secundaryColor};
  text-transform: uppercase;

  
  @media (max-width: 768px) {
    font-size: 1.7rem;
    font-weight: 900;
  }
`;

export const Text = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  color:${desctext};
  width: 80%;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin:1rem 0;
  }
`;
export const Button = styled.button`
  font-size: 1.5rem;
  width: 230px;
  height: 50px;
  text-transform: uppercase;
  background: ${primaryColor};
  color: ${fourtyColor};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  transition: 1s;

  a {
    text-decoration: none;
    color: inherit;
  }


  @media (max-width: 768px) {
    font-size: 1.3rem;
    width: 210px;
    height: 40px;
    padding: 0;
  }
`;

export const ImagemDiv = styled.div`
  width: 420px;
  height: 460px;
  border-radius: 20px;
  overflow: hidden; 
  
  @media (max-width: 768px) {
    width: 320px;
    height: 360px;
 
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Icon = styled.div`
  display: flex;
  gap: 10px; /* Espaçamento entre os ícones */
`;

export const SvgIcon = styled.svg`
margin-top: 20px;
  width: 45px;
  cursor: pointer;
  fill: ${secundaryColor}; 
`;